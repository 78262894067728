.translate-texture {
  @apply transition-all duration-500 ease-in-out;
}

input:-webkit-autofill,
input:-webkit-autofill::first-line,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
  -webkit-text-fill-color: #0a0013;
  background-color: #ffffff;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.no-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.rw-button {
  @apply flex cursor-pointer justify-center rounded border-0 bg-gray-200 px-4 py-1 text-xs font-semibold uppercase leading-loose tracking-wide text-text-caption no-underline transition duration-100;
}
.rw-button:hover {
  @apply bg-gray-500 text-white;
}
.rw-button.rw-button-small {
  @apply rounded-sm px-2 py-1 text-xs;
}
.rw-button.rw-button-green {
  @apply bg-green-500 text-white;
}
.rw-button.rw-button-green:hover {
  @apply bg-green-700;
}
.rw-button.rw-button-blue {
  @apply bg-blue-500 text-white;
}
.rw-button.rw-button-blue:hover {
  @apply bg-blue-700;
}
.rw-button.rw-button-red {
  @apply bg-red-500 text-white;
}
.rw-button.rw-button-red:hover {
  @apply bg-red-700 text-white;
}
.rw-button-icon {
  @apply mr-1 text-xl leading-5;
}
.rw-button-group {
  @apply mx-2 my-3 flex justify-center;
}
.rw-button-group .rw-button {
  @apply mx-1;
}

.leaflet-pane,
.leaflet-control,
.leaflet-bottom,
.leaflet-top {
  z-index: 9 !important;
}
